<template>
  <el-dialog
    append-to-body
    width="600px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop="account" label="手机号">
        <el-select
          v-model="form.data.account"
          :disabled="isChange"
          filterable
          allow-create
          default-first-option
          placeholder="请选择"
          @change="handleAccountChange">
          <el-option
            v-for="item in userListOpt"
            :key="item.id"
            :label="item.account"
            :value="item.account"
            >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="name" label="代理姓名">
        <el-input placeholder="请输入代理姓名" v-model="form.data.name" clearable :disabled="isdisabled || isChange"></el-input>
      </el-form-item>
      <el-form-item prop="password" label="密码" v-if="this.isdisabled === false && this.isChange === false">
        <el-input placeholder="请输入密码" v-model="form.data.password" clearable></el-input>
      </el-form-item>
      <el-form-item prop="school_id" label="绑定学校">
        <el-select v-model="form.data.school_id" clearable filterable placeholder="请选择">
          <el-option
            v-for="item in schoolListOpt"
            :key="item.id"
            :label="item.name+(item.campus_name ? '—' + item.campus_name : '')"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop='id_card' label='身份证照片' ref="elUpload">
        <el-upload
          :disabled="isChange"
          class="avatar-uploader"
          action=""
          :headers="{ token: token }"
          :show-file-list="false"
          :before-upload="beforeAvatarUpload"
          :http-request="httpRequest"
          accept="image/jpeg, image/png, image/gif"
          >
          <img v-if="this.form.data.id_card" :src="this.form.data.id_card" class="avatar" style="object-fit: contain;">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import {getStorage} from '@/storage'
  export default {
    name: 'AgentEdit',
    data() {
      return {
        token: getStorage('token'),
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            account: '',
            name: '',
            password: '',
            school_id: '',
            id_card: '',
          },
          rules: {
            account: [{ required: true, message:'请输入手机号', trigger: 'change'}],
            name: [{ required: true,  message:'请输入代理姓名', trigger: 'change'}],
            password: [{ required: true, message:'请输入密码',  trigger: 'blur'}],
            school_id: [{ required: true, message:'请绑定学校',  trigger: 'change'}],
            id_card: [{ required: true, message:'请上传身份证照片',  trigger: 'change,[blur]'}],
          }
        },
        typeOpt: [],
        userListOpt: [],
        schoolListOpt: [],
        isdisabled: false,
        schoolObj:{},
        value: []

      }
    },
    created() {
      this.getUserList();
    },
    methods: {
      // 获取手机号
      getUserList() {
        this.$http.get('/admin/agent/userList',{params:{}}).then(res => {
          if(res.code === 1) {
            console.log(res, 99)
            this.userListOpt = res.data.list;
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      // 获取学校列表(agent:1未被绑定的学校)
      async getShool() {
        // 判断如果是新增时学校列表先清空
        this.schoolListOpt = this.isChange ? this.schoolListOpt : []
        await this.$http.get('/admin/school/list',{params: {count:10000,agent:1}}).then(res => {
          if(res.code === 1) {
            this.schoolListOpt = this.schoolListOpt.concat(res.data.list);
          }
        })
      },
      // 获取详情
      async getDetail(row) {
        if(!!row.id) {
          this.isChange = true
          let str = {
            id: row.agent_school,
            name: row.college_name,
            campus_name: row.campus_name,
          }
          this.schoolListOpt.push(str)
          common.deepClone(this.form.data, row)
          this.form.data.name = row.nickname;
          this.form.data.school_id = row.agent_school
        }
        await this.getShool();
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        this.form.data = {
          account: '',
          name: '',
          password: '',
          school_id: '',
          id_card: '',
        }
        this.schoolListOpt = []
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            console.log(this.form.data);
            this.form.loading = true;
            let apiUrl = !this.isChange ? '/admin/agent/add' : '/admin/agent/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 5;
        if (!isLt5M) {
          this.$message.error('上传文件大小不能超过 5MB!');
        }
        return isLt5M;
      },
      httpRequest(file) {
        let formData = new FormData();
        formData.append('file', file.file);
        this.$http.post('common/qiniu/uploadFile', {formData, type:'upload'}).then(res => {
          if(res.code === 1) {
            this.form.data.id_card = res.data.all_path_url;
            this.$refs.elUpload.clearValidate()
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      handleAccountChange(account) {
        console.log(account)
        let arr = this.userListOpt.filter(item => {
          return item.account === account
        })
        this.form.data.name = arr.length > 0 ? arr[0].nickname : ''
        this.isdisabled = arr.length > 0 ? true : false;
        
      },
      // handleAccountChange(id) {
      //   let arr = this.userListOpt.filter(item => {
      //     return item.id === id
      //   })
      //   this.form.data.name = arr[0].nickname
      //   this.form.data.account = arr[0].account
      //   this.isdisabled = true;
        
      // },
      dataFilter(val) {
        console.log(val)
        this.form.data.account = val;
        this.form.data.name = '';
        this.isdisabled = false;
        this.form.rules.name = [{ required: true, message:'请输入代理姓名', trigger: 'blur'}]
      },
    }
  }
</script>
