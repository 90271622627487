<template>
  <div class="proposal__wrap">
    <div class="section shadow filter__wrap">
      <div>
        <el-radio-group v-model="table.params.is_freeze" @change="handleTabs">
          <el-radio-button :label="1">开通中</el-radio-button>
          <el-radio-button :label="2">已冻结</el-radio-button>
        </el-radio-group>
      </div>
      <div class="flex">
        <el-form ref="elFormDom" inline :model="table.params">
          <el-form-item label="" prop="name">
            <el-input clearable v-model="table.params.name" placeholder="请输入代理名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="account">
            <el-input clearable v-model="table.params.account" placeholder="请输入代理手机号"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">查询 </el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" icon="el-icon-plus" size="mini" @click="showEdit">添加代理</el-button>
      </div>
      
    </div>
    <!-- 表格 -->
    <div class="table__wrap">
      <VTable
        has-pagionation
        title=''
        addText='添加'
        :field="field"
        :loading="table.loading"
        :data="table.data"
        :page='table.params.page'
        :pageSize='table.params.count'
        :total='table.total'
        :tree-props="{children: 'children', hasChildren: 'has_child'}"
        :hasPagionation="true"
        :default-expand-all="true"
        :showOverflowTooltip="true"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
      >
        <template v-slot:campus_name="{row}">
          <span>{{row.college_name}}{{row.campus_name ? '—' + row.campus_name : ''}}</span>
        </template>
        <template v-slot:action="{row}">
          <el-button type="text" size="mini" @click="pushPage('order',row)">交易明细</el-button>
          <el-button v-if="row.is_freeze === 1" type="text" size="mini" @click="pushPage('salesman',row)">业务员</el-button>
          <el-button v-if="row.is_freeze === 1" type="text" icon="el-icon-edit" @click="showEdit(row)">编辑</el-button>
          <el-button v-if="row.is_freeze === 1" type="text" icon="el-icon-warning" class="delBtn" @click="handleFreeze(row)">冻结</el-button>  
        </template>
      </VTable>
    </div>

    <edit ref="edit" @refresh="getTable"></edit>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue'
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'AgentList',
  mixins:[mixinTable],
  components: {
    VTable,
    Edit,
  },
  data() {
    return {
      field: [
        { name: "nickname", label: "代理名称", hidden: false },
        { name: "account", label: "手机号", hidden: false },
        { name: "campus_name", label: "管理学校", hidden: false },
        { name: "update_time", label: "更新时间", hidden: false },
        { name: "action", label: "操作", width: "260", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          name: '',
          account: '',
          is_freeze: 1, // 类型 1:正常 2:冻结
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.$http.get('/admin/agent/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 切换状态
    handleTabs() {
      this.table.params.page = 1;
      this.table.data = [];
      this.getTable();
    },
    showEdit(row) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(row)
      // if (!!row.id) {
      //   dom.getDetail(row)
      // }
      dom = null
    },
    // 冻结
    handleFreeze(row) {
      this.$confirm('请确认此操作？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post('/admin/agent/operation', {id: row.id}).then(res => {
          if(res.code === 1) {
            this.$message.success('操作成功！')
            this.getTable();
          }
        })
      }).catch(() => {})
    },
    pushPage(type,row) {
      sessionStorage.setItem('agentId', row.id)
      this.$router.push({
        // path: '/agent/salesman',
        path: type === 'order' ? '/agent/order' : '/agent/salesman',
        query: {
          agentId: row.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .flex {
    display: flex;
  }
  .proposal__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px;

    .filter__wrap {
      padding: 10px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }

    .table__wrap {
      flex: 1;
      padding: 0;
      box-sizing: border-box;
    }
  }
</style>